import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import classes from "./SearchBar.module.scss";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";

export interface IMyButtonProps {
  onClick: (
    e: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLFormElement>
  ) => void;
  styling?: string;
}

export const MyButton: React.FC<IMyButtonProps> = (props) => {
  const isSmall = useMediaQuery({ query: "(min-width: 621px)" });
  return (
    <div className={clsx(classes.button)} {...props}>
      <SearchIcon />
      {isSmall && <span>Search</span>}
    </div>
  );
};
