import { Dispatch } from "react";
import AxiosApi from "../../api/axiosInstance";
import {
  CommonAction,
  CommonActionTypes,
  FilterType,
  IHashKeyword,
} from "../../types/commonTypes";
import { IComputationCost } from "../../types/constants";

const QUESTION_API_URL =
  "https://hd7zu14hce.execute-api.us-east-1.amazonaws.com/qa?trace=true";
export const addKeyword = (keyword: IHashKeyword) => {
  return (dispatch: Dispatch<CommonAction>) => {
    dispatch({ type: CommonActionTypes.ADD_KEYWORD, payload: keyword });
  };
};

export const removeKeyword = (keyword: IHashKeyword) => {
  return (dispatch: Dispatch<CommonAction>) => {
    dispatch({ type: CommonActionTypes.REMOVE_KEYWORD, payload: keyword });
  };
};

export const addHashtag = (hashtag: IHashKeyword) => {
  return (dispatch: Dispatch<CommonAction>) => {
    dispatch({ type: CommonActionTypes.ADD_HASHTAG, payload: hashtag });
  };
};

export const removeHashtag = (hashtag: IHashKeyword) => {
  return (dispatch: Dispatch<CommonAction>) => {
    dispatch({ type: CommonActionTypes.REMOVE_HASHTAG, payload: hashtag });
  };
};

export const addSource = (source: IHashKeyword) => {
  return (dispatch: Dispatch<CommonAction>) => {
    dispatch({ type: CommonActionTypes.ADD_SOURCE, payload: source });
  };
};

export const removeSource = (source: IHashKeyword) => {
  return (dispatch: Dispatch<CommonAction>) => {
    dispatch({ type: CommonActionTypes.REMOVE_SOURCE, payload: source });
  };
};

export const askQuestion = (
  question: string,
  hashtags: IHashKeyword[],
  keywords: IHashKeyword[],
  sources: IHashKeyword[],
  computationCost: IComputationCost
) => {
  return async (dispatch: Dispatch<CommonAction>) => {
    try {
      dispatch({ type: CommonActionTypes.API_REQUEST });
      dispatch({ type: CommonActionTypes.ASK_QUESTION, payload: question });

      let outputKeywords =
        keywords.length > 0
          ? { keywords: keywords.map((kw) => kw.item.name) }
          : null;
      let outputHashtags =
        hashtags.length > 0
          ? { hashtags: hashtags.map((kw) => `#${kw.item.name}`) }
          : null;
      let outputSources =
        sources.length > 0
          ? { sources: sources.map((kw) => kw.item.domain) }
          : null;

      let targetUrl = `${QUESTION_API_URL}&retriever_k=${computationCost.k_retriever}&reader_k=${computationCost.k_reader}`;
      console.log(targetUrl);

      let filters = { ...outputKeywords, ...outputHashtags, ...outputSources };
      console.log(filters);

      // let filters = { keywords: [...kw], domain: [...src], hashtags: [...ht] };
      // const answer = `Dummy answer for [${question}] from redux action at ${Date.now()}! Keywords: [${kw}];  Hashtags: [${ht}];  Sources: [${src}]`;
      const questionRequest = await AxiosApi.instance.post(targetUrl, {
        engines: computationCost.engine,
        filters,
        question,
      });
      console.log(questionRequest);

      dispatch({
        type: CommonActionTypes.ANSWER_SUCCESS,
        payload: questionRequest.data.answer,
      });
      dispatch({
        type: CommonActionTypes.ANSWER_SOURCE,
        payload: questionRequest.data.sources
      });
      // dispatch({
      //   type: CommonActionTypes.ANSWER_SUCCESS,
      //   payload: answer,
      // });
      dispatch({ type: CommonActionTypes.API_REQUEST_SUCCESS });
    } catch (error: any) {
      // console.log("ERROR in askQuestion: ");
      // console.log(Object.keys(error));
      // console.log(error.response);

      dispatch({ type: CommonActionTypes.ANSWER_FAILURE });
      dispatch({
        type: CommonActionTypes.API_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const setActiveFilter = (filter: FilterType) => {
  return (dispatch: Dispatch<CommonAction>) => {
    dispatch({ type: CommonActionTypes.SET_ACTIVE_FILTER, payload: filter });
  };
};

export const addBatchFilters = (
  hashtags: IHashKeyword[],
  keywords: IHashKeyword[],
  sources: IHashKeyword[]
) => {
  return (dispatch: Dispatch<CommonAction>) => {
    dispatch({
      type: CommonActionTypes.ADD_BATCH_FILTERS,
      hashtags,
      keywords,
      sources,
    });
  };
};

export const setEmailAction = (email: string) => {
  return (dispatch: Dispatch<CommonAction>) => {
    dispatch({ type: CommonActionTypes.SET_EMAIL, payload: email });
  };
};

export const setComputationCostAction = (item: IComputationCost) => {
  return (dispatch: Dispatch<CommonAction>) => {
    dispatch({ type: CommonActionTypes.SET_COMPUTATION_COST, payload: item });
  };
};
