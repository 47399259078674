import clsx from "clsx";
import { Lists } from "./Lists";
import mainClasses from "./Main.module.scss";
import classes from "./AddToList.module.scss";
import { useNavigate } from "react-router";
import { useTypedSelector } from "../redux/useTypedSelector";
import { AddToListModal } from "../components/Modal/AddToListModal";
import { CustomModal } from "../components/Modal/CustomModal";
import { useState } from "react";
import { useActions } from "../redux/useActions";
import { useDispatch } from "react-redux";
import { CommonActionTypes } from "../types/commonTypes";

export interface IAnswerRouteState {
  question?: string;
  answer?: string;
}
export const AddToList: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [listTitle, setListTitle] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToList,  } = useActions();
  const currentAnswer = useTypedSelector((state) => state.gql.currentAnswer);
  const handleCloseModal = () => {
    dispatch({ type: CommonActionTypes.CLEAR_FILTERS });
    setModalOpen(false);
  };
  const currentQuestion = useTypedSelector((state) => state.common.currentItem);

  const handleAddToList = (...args: any[]) => {
    // console.log(`listItem`, args[1]);
    if (currentAnswer) {
      let modifiedAnswers = args[1].answers
        ? [...args[1].answers, currentAnswer.id]
        : [currentAnswer.id];
      addToList(args[1].id, modifiedAnswers, args[1]._version);
      setListTitle(args[1].name);
      setModalOpen(true);

      setTimeout(() => setModalOpen(false), 1500);
      setTimeout(() => {
        dispatch({ type: CommonActionTypes.CLEAR_FILTERS });
        navigate(`/report/${args[1].id}`);
      }, 1500);
    } else {
      navigate(`/`);
    }
  };

  return (
    <>
      <h3 className={clsx(mainClasses.text, classes.header)}>
        {`Add “${currentQuestion.question}” to the list:`}
      </h3>
      <Lists
        itemClickCallback={handleAddToList}
        parentComponent="add-to-report"
      />
      {modalOpen && (
        <CustomModal handleClose={handleCloseModal} isActive={modalOpen}>
          <AddToListModal list={listTitle} />
        </CustomModal>
      )}
    </>
  );
};
