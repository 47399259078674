import { IInputProps } from "../SearchBar/MyInput";
import classes from "./Filters.module.scss";
import chipClasses from "./FilterChip.module.scss";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import { useTypedSelector } from "../../redux/useTypedSelector";
import clsx from "clsx";

export const TagInput: React.FC<IInputProps> = (props) => {
  const handleAddTag = (e: React.MouseEvent<HTMLElement>) => {
    if (!props.value) return;
    if (props.value.length < 2) return;
    if (props.onClick) props.onClick(e);
  };
  const handleChangeTag = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.value.length === 0 && e.target.value === " ") return;
    props.onChange(e);
  };
  const activeFilter = useTypedSelector((state) => state.common.activeFilter);
  const typedPlaceholder = () => {
    switch (activeFilter) {
      case "ht":
        return "#your_hashtag";
      case "kw":
        return "+your_keyword";
      case "src":
        return "@your_source";
    }
  };
  return (
    <div className={classes.chipinput}>
      <input
        type={"text"}
        className={clsx(
          activeFilter && chipClasses[activeFilter],
          classes.textBaseInput
        )}
        value={props.value}
        onChange={handleChangeTag}
        placeholder={typedPlaceholder()}
      />
      <IconButton className={classes.chipbutton} onClick={handleAddTag}>
        <AddCircleOutlineIcon color="primary" />
      </IconButton>
    </div>
  );
};
