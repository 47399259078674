import { useEffect, useState } from "react";
import classes from "./QuestionItem.module.scss";
import mainClasses from "../../layouts/Main.module.scss";
import Switch from "@mui/material/Switch";
import { UnderlineButton } from "../Buttons/UnderlineButton";
import { useMediaQuery } from "react-responsive";
import { CustomModal } from "../Modal/CustomModal";
import { SendEmailModal } from "../Modal/SendEmailModal";
import { Answer, DeleteAnswerInput, UpdateListInput } from "../../API";
import { useTypedSelector } from "../../redux/useTypedSelector";
import { useActions } from "../../redux/useActions";
import { IconButton } from "../Buttons/IconButton";

export interface IQuestionItemProps {
  listItem: Answer;
}
export const QuestionItem: React.FC<IQuestionItemProps> = ({ listItem }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [readMore, setReadMore] = useState<boolean>(false);
  const [isSendToEmail, setIsSendToEmail] = useState<boolean>(false);

  const isSmall = useMediaQuery({ query: "(min-width: 621px)" });
  const currentList = useTypedSelector((state) => state.gql.currentList);
  const { updateQuestionMailingStatusAction, deleteAnswerAction } =
    useActions();

  const hyperlinks = () => {
    let links: JSX.Element[];
    let sources = listItem.filter ? listItem.filter.sources : [];
    if (sources) {
      links = sources.map((hl) => {
        return (
          <a
            href={hl.domain}
            className={mainClasses.source_link}
            target={"_blank"}
            rel="noreferrer"
          >{`${hl.name}`}</a>
        );
      });
    } else {
      links = [];
    }
    return links;
  };

  useEffect(() => {
    if (
      currentList?.deactivated &&
      currentList?.deactivated?.indexOf(listItem.id) === -1
    )
      setIsSendToEmail(true);
    if (!currentList?.deactivated) setIsSendToEmail(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReadMore = () => {
    setReadMore((prev) => !prev);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const formatDescription = (text: string) => {
    let readable = text.slice(0, 150);
    let dots = text.length < 150 ? null : "...     ";
    return (
      <>
        {readable}
        {dots}
        {dots && (
          <UnderlineButton
            type="expand"
            callback={handleReadMore}
            expanded={readMore}
          />
        )}
      </>
    );
  };

  const handleAddSelfToEmailList = () => {
    // if (!email) {
    //   // TODO: add alert about empty email field
    //   return;
    // }
    let deactivatedItems = currentList!.deactivated as string[];
    let input: UpdateListInput;
    if (isSendToEmail) {
      deactivatedItems = currentList?.deactivated
        ? [...currentList?.deactivated, listItem.id]
        : [listItem.id];
      input = {
        id: currentList?.id!,
        _version: currentList?._version,
        deactivated: deactivatedItems,
      };
    } else {
      deactivatedItems = deactivatedItems
        ? deactivatedItems.filter((itm) => itm !== listItem.id)
        : [];
      input = {
        id: currentList?.id!,
        _version: currentList?._version,
        deactivated: deactivatedItems,
      };
    }
    // updateListAction(input);
    updateQuestionMailingStatusAction(input);
    setIsSendToEmail((prev) => !prev);
    setModalOpen(true);
    setTimeout(() => setModalOpen(false), 2000);
  };

  const handleDeleteAnswer = () => {
    let answerInput: DeleteAnswerInput = {
      id: listItem.id,
      _version: listItem._version,
    };
    let filteredAnswers =
      currentList &&
      currentList.answers!.filter((answer) => answer !== listItem.id);
    let listInput: UpdateListInput = {
      id: currentList?.id!,
      _version: currentList?._version,
      answers: filteredAnswers,
    };
    deleteAnswerAction(answerInput, listInput);
    console.log("click");
  };
  return (
    <div className={classes.item}>
      <div className={classes.content}>
        <h3 className={classes.title}>{listItem.question}</h3>
        <p data-type="listItem" className={classes.description}>
          {!readMore ? formatDescription(listItem.answer!) : listItem.answer!}
          {hyperlinks().length > 0 && " | "}
          {hyperlinks()}
        </p>
        {readMore && (
          <UnderlineButton
            type="expand"
            callback={handleReadMore}
            expanded={readMore}
          />
        )}
      </div>
      <div className={classes.controls}>
        {!isSmall && (
          <span className={classes.controls_text}>Send on E-mail</span>
        )}
        <Switch onChange={handleAddSelfToEmailList} checked={isSendToEmail} />
        <IconButton
          caption="Delete question"
          callback={handleDeleteAnswer}
          icon="deleteList"
        />
      </div>
      <CustomModal handleClose={handleCloseModal} isActive={modalOpen}>
        <SendEmailModal status={isSendToEmail} />
      </CustomModal>
    </div>
  );
};
