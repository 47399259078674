import React, { FocusEvent } from "react";
import clsx from "clsx";
import classes from "./SearchBar.module.scss";

export interface IInputProps {
  value: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  styling?: string;
  type?: string;
}

export const MyInput: React.FC<IInputProps> = (props) => {
  const style = clsx(classes.textBaseInput, props.styling);
  return (
    <input
      {...props}
      type={props.type ? props.type : "text"}
      className={style}
    />
  );
};
