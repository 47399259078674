import { Dispatch } from "react";
import {
  ILoginParams,
  AuthAction,
  AuthActionTypes,
  ILoginResponse,
} from "../../types/authTypes";

export const login = ({ password, username }: ILoginParams) => {
  return async (dispatch: Dispatch<AuthAction>) => {
    try {
      dispatch({ type: AuthActionTypes.AUTH_API_REQUEST });
      // const data: ILoginResponse = await authApi.login(username, password);

      // localStorage.setItem("token", data.access_token);

      // dispatch({
      //   type: AuthActionTypes.LOGIN_SUCCESS,
      //   payload: data,
      // });
    } catch (e: any) {
      dispatch({ type: AuthActionTypes.LOGIN_FAILURE, payload: e.message });
      throw new Error();
    }
  };
};
