import React from "react";
import classes from "../Buttons/FilterButton.module.scss";
import clsx from "clsx";
import { costValues, IComputationCost } from "../../types/constants";
import { MenuItem } from "@mui/material";
import { useActions } from "../../redux/useActions";

export interface ISelectCostProps {
  activeStyling: string;
}
export const SelectCost: React.FC<ISelectCostProps> = ({ activeStyling }) => {
  const { setComputationCostAction } = useActions();
  const menuItemClick = (item: IComputationCost) => {
    setComputationCostAction(item);
  };
  const menuItems = costValues.map((itm, idx) => (
    <MenuItem
      key={idx}
      value={itm.title}
      classes={{ root: classes.menu_item }}
      onClick={() => {
        menuItemClick(itm);
      }}
    >
      {itm.title}
    </MenuItem>
  ));

  return (
    <div
      className={clsx(
        classes.select_base,
        classes.select_panel,
        classes[activeStyling]
      )}
    >
      {menuItems}
    </div>
  );
};
