import { QuestionItem } from "../components/QuestionItem/QuestionItem";
import classes from "./Questions.module.scss";
import { useMediaQuery } from "react-responsive";
import { useActions } from "../redux/useActions";
import { useEffect } from "react";
import { useTypedSelector } from "../redux/useTypedSelector";
import { CircularProgress } from "@mui/material";
import { FbLoader } from "../components/FbLikeLoader/FbLoader";

export interface IQuestionsProps {
  idList: string[];
}
export const Questions: React.FC<IQuestionsProps> = ({ idList }) => {
  const isSmall = useMediaQuery({ query: "(min-width: 621px)" });

  const { getAnswerForCurrentListAction } = useActions();
  const answersInCurrentList = useTypedSelector(
    (state) => state.gql.currentListAnswers
  );
  const isLoading = useTypedSelector((state) => state.common.isLoading);

  useEffect(() => {
    if (idList.length > 0 && !isLoading) getAnswerForCurrentListAction(idList!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idList]);

  const listItems = answersInCurrentList.map((itm, idx) => (
    <QuestionItem key={idx} listItem={itm!} />
  ));

  if (isLoading) {
    return <FbLoader />;
  }
  return (
    <>
      <div className={classes.header}>
        {isSmall && (
          <span className={classes.title}>
            Include question in the next report
          </span>
        )}
      </div>
      {listItems}
    </>
  );
};
