import React, { useEffect, useState } from "react";
import { SimpleButton } from "../Buttons/SimpleButton";
import { MyInput } from "../SearchBar/MyInput";
import classes from "./Modal.module.scss";
import { ReactComponent as SuccessIcon } from "../../assets/success.svg";
import { useTypedSelector } from "../../redux/useTypedSelector";
import { useActions } from "../../redux/useActions";

export interface IModalProps {
  closeModal: () => void;
}
export const EmailModal: React.FC<IModalProps> = ({ closeModal }) => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);

  const storeEmail = useTypedSelector((state) => state.common.email);
  const { setEmailAction } = useActions();

  useEffect(() => {
    setEmail(storeEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const validateEmail = () => {
    const reg: RegExp =
      /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/;
    return reg.test(email);
  };

  const handleSaveEmail = async () => {
    if (validateEmail()) {
      setEmailAction(email);
      setEmail("");
      setSuccess(true);
      setTimeout(closeModal, 700);
      // closeModal();
    }
  };
  if (success) {
    return (
      <>
        <h3 className={classes.title}>E-mail successfully added!</h3>
        <SuccessIcon />
      </>
    );
  }
  return (
    <>
      <h3 className={classes.title}>Add your e-mail</h3>
      <MyInput
        onChange={handleChangeEmail}
        value={email}
        placeholder="example@gmail.com"
        styling={classes.emailInput}
        type="email"
      />
      <SimpleButton
        caption="Submit"
        callback={handleSaveEmail}
        styling={classes.saveMailButton}
      />
    </>
  );
};
