import { AmplifyClass } from "@aws-amplify/core";
import Amplify from "aws-amplify";
import awsmobile from "../aws-exports";

Amplify.configure(awsmobile);
export const amplifyClient = Amplify.API;
// export const amplifyClient = new AWSAppSyncClient({
//   url: awsmobile.aws_appsync_graphqlEndpoint,
//   region: awsmobile.aws_appsync_region,
//   auth: {
//     type: AUTH_TYPE.API_KEY,
//     apiKey: awsmobile.aws_appsync_apiKey,
//   },
// });

export class GqlApi {
  private static myInstance: GqlApi;
  public static getInstance() {
    if (!GqlApi.myInstance) {
      GqlApi.myInstance = new GqlApi();
    }
    return GqlApi.myInstance;
  }

  private constructor() {
    this.amplifyCls.configure(awsmobile);
  }
  public amplifyCls: AmplifyClass = Amplify;
  public API = this.amplifyCls.API;
  // public API = ()=>this.amplifyCls.API.graphql(graphqlOperation())
}
