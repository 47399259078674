import { Source } from "../API";
import { IComputationCost } from "./constants";

// state
export interface ICommonState {
  isLoading: boolean;
  localLoading: boolean;
  error: string;
  email: string;
  keywords: IHashKeyword[];
  hashtags: IHashKeyword[];
  sources: IHashKeyword[];
  currentItem: IQuestionAnswer;
  activeFilter: FilterType;
  computationCost: IComputationCost;
}
// action types
export enum CommonActionTypes {
  API_REQUEST = "API_REQUEST",
  API_REQUEST_SUCCESS = "API_REQUEST_SUCCESS",
  API_REQUEST_FAILURE = "API_REQUEST_FAILURE",
  ADD_KEYWORD = "ADD_KEYWORD",
  REMOVE_KEYWORD = "REMOVE_KEYWORD",
  ADD_HASHTAG = "ADD_HASHTAG",
  REMOVE_HASHTAG = "REMOVE_HASHTAG",
  ADD_SOURCE = "ADD_SOURCE",
  REMOVE_SOURCE = "REMOVE_SOURCE",
  ADD_BATCH_FILTERS = "ADD_BATCH_FILTERS",
  CLEAR_FILTERS = "CLEAR_FILTERS",
  ASK_QUESTION = "ASK_QUESTION",
  ANSWER_SUCCESS = "ANSWER_SUCCESS",
  ANSWER_FAILURE = "ANSWER_FAILURE",
  SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER",
  SET_EMAIL = "SET_EMAIL",
  SET_LOCAL_LOADING = "SET_LOCAL_LOADING",
  SET_COMPUTATION_COST = "SET_COMPUTATION_COST",
  ANSWER_SOURCE = "ANSWER_SOURCE",
}

interface IAddKeywordAction {
  type: CommonActionTypes.ADD_KEYWORD;
  payload: IHashKeyword;
}
interface IRemoveKeywordAction {
  type: CommonActionTypes.REMOVE_KEYWORD;
  payload: IHashKeyword;
}
interface IAddHashtagAction {
  type: CommonActionTypes.ADD_HASHTAG;
  payload: IHashKeyword;
}
interface IRemoveHashtagAction {
  type: CommonActionTypes.REMOVE_HASHTAG;
  payload: IHashKeyword;
}
interface IAddSourceAction {
  type: CommonActionTypes.ADD_SOURCE;
  payload: IHashKeyword;
}
interface IRemoveSourceAction {
  type: CommonActionTypes.REMOVE_SOURCE;
  payload: IHashKeyword;
}
interface IAddBatchFiltersAction {
  type: CommonActionTypes.ADD_BATCH_FILTERS;
  hashtags: IHashKeyword[];
  keywords: IHashKeyword[];
  sources: IHashKeyword[];
}
interface IClearFiltersAction {
  type: CommonActionTypes.CLEAR_FILTERS;
}
interface IAskQuestionAction {
  type: CommonActionTypes.ASK_QUESTION;
  payload: string;
}
interface IAnswerSuccessAction {
  type: CommonActionTypes.ANSWER_SUCCESS;
  payload: string;
}
interface IAnswerSourceAction {
  type: CommonActionTypes.ANSWER_SOURCE;
  payload: IQuestionSource[];
}
interface IAnswerFailureAction {
  type: CommonActionTypes.ANSWER_FAILURE;
}
interface ISetActiveFilterAction {
  type: CommonActionTypes.SET_ACTIVE_FILTER;
  payload: FilterType;
}
interface IApiRequestAction {
  type: CommonActionTypes.API_REQUEST;
}
interface IApiRequestSuccessAction {
  type: CommonActionTypes.API_REQUEST_SUCCESS;
}
interface IApiRequestFailureAction {
  type: CommonActionTypes.API_REQUEST_FAILURE;
  payload: string;
}
interface ISetEmailAction {
  type: CommonActionTypes.SET_EMAIL;
  payload: string;
}
interface ISetLocalLoadingAction {
  type: CommonActionTypes.SET_LOCAL_LOADING;
  payload: boolean;
}
interface ISetComputationCostAction {
  type: CommonActionTypes.SET_COMPUTATION_COST;
  payload: IComputationCost;
}
export type CommonAction =
  | IApiRequestAction
  | IApiRequestSuccessAction
  | IApiRequestFailureAction
  | IAddKeywordAction
  | IRemoveKeywordAction
  | IAddHashtagAction
  | IRemoveHashtagAction
  | IAddSourceAction
  | IRemoveSourceAction
  | IAskQuestionAction
  | IAnswerSuccessAction
  | IAnswerSourceAction
  | IAnswerFailureAction
  | ISetActiveFilterAction
  | IAddBatchFiltersAction
  | IClearFiltersAction
  | ISetEmailAction
  | ISetLocalLoadingAction
  | ISetComputationCostAction;

// object types
export type FilterType = "ht" | "kw" | "src" | null;
export interface IHashKeyword {
  type: FilterType;
  item: Source;
}
export interface  IQuestionSource {
  link: string;
  name: string;
  answer: string;
  context: string;
  highlighted_link: string;
}
export interface IQuestionAnswer {
  question?: string;
  answer?: string;
  sourceUrl?: IQuestionSource[];
}
