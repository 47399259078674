import React from "react";
import { SimpleButton } from "../Buttons/SimpleButton";
import classes from "./Modal.module.scss";
import buttonClasses from "../Buttons/Buttons.module.scss";

export interface IDeleteListModalProps {
  list: string;
  deleteListCallback: () => void;
  cancelDeletingCallback: () => void;
}
export const DeleteListModal: React.FC<IDeleteListModalProps> = ({
  list,
  deleteListCallback,
  cancelDeletingCallback,
}) => {
  const text = `Are you sure you want to delete [${list}] list?`;

  return (
    <>
      <h3 className={classes.title}>{text}</h3>
      <div className={classes.button_group}>
        <SimpleButton
          caption="Delete"
          callback={deleteListCallback}
          styling={buttonClasses.filled}
        />
        <SimpleButton
          caption="Cancel"
          callback={cancelDeletingCallback}
          styling={buttonClasses.outlined}
        />
      </div>
    </>
  );
};
