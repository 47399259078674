import { graphqlOperation } from "aws-amplify";

import {
  ICreateAnswerResponse,
  ICreateListResponse,
  IDeleteAnswerResponse,
  IDeleteListResponse,
  IUpdateAnswerResponse,
  IUpdateListResponse,
  Mutation,
} from "../../types/graphql/mutationService";
import { GqlApi } from "../amplifyClient";
import {
  createAnswer,
  createList,
  deleteAnswer,
  deleteList,
  updateAnswer,
  updateList,
} from "../../graphql/mutations";
import {
  CreateAnswerInput,
  CreateListInput,
  DeleteAnswerInput,
  DeleteListInput,
  ModelListConditionInput,
  UpdateAnswerInput,
  UpdateListInput,
} from "../../API";
import { IBaseResponse } from "../../types/graphql/queryService";

export class MutationService implements Mutation {
  private static myInstance: MutationService;
  public static getInstance() {
    if (!MutationService.myInstance) {
      MutationService.myInstance = new MutationService();
    }
    return this.myInstance;
  }
  private client;

  private constructor() {
    this.client = GqlApi.getInstance().API;
  }
  // =================== Main methods ====================
  // TODO: add try - catch for api calls
  async createList(
    input: CreateListInput,
    condition?: ModelListConditionInput
  ) {
    const response: IBaseResponse<ICreateListResponse> =
      await this.client.graphql(
        graphqlOperation(createList, { input, condition })
      );
    // console.log("LOG FROM createList");
    // console.log(response);
    return response.data;
  }
  async updateList(
    input: UpdateListInput,
    condition?: ModelListConditionInput
  ) {
    const response: IBaseResponse<IUpdateListResponse> =
      await this.client.graphql(
        graphqlOperation(updateList, { input, condition })
      );
    // console.log("LOG FROM updateList");
    // console.log(response);
    return response.data;
  }
  async deleteList(
    input: DeleteListInput,
    condition?: ModelListConditionInput
  ) {
    const response: IBaseResponse<IDeleteListResponse> =
      await this.client.graphql(
        graphqlOperation(deleteList, { input, condition })
      );
    // console.log("LOG FROM deleteList");
    // console.log(response);
    return response.data;
  }
  async createAnswer(
    input: CreateAnswerInput,
    condition?: ModelListConditionInput
  ) {
    const response: IBaseResponse<ICreateAnswerResponse> =
      await this.client.graphql(
        graphqlOperation(createAnswer, { input, condition })
      );
    // console.log("LOG FROM createAnswer");
    // console.log(response);
    return response.data;
  }
  async updateAnswer(
    input: UpdateAnswerInput,
    condition?: ModelListConditionInput
  ) {
    const response: IBaseResponse<IUpdateAnswerResponse> =
      await this.client.graphql(
        graphqlOperation(updateAnswer, { input, condition })
      );
    // console.log("LOG FROM updateAnswer");
    // console.log(response);
    return response.data;
  }
  async deleteAnswer(
    input: DeleteAnswerInput,
    condition?: ModelListConditionInput
  ) {
    const response: IBaseResponse<IDeleteAnswerResponse> =
      await this.client.graphql(
        graphqlOperation(deleteAnswer, { input, condition })
      );
    // console.log("LOG FROM deleteAnswer");
    // console.log(response);
    return response.data;
  }
}
