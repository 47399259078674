import { Container } from "@mui/material";
import { Routes, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Answer } from "./layouts/Answer";
import { Lists } from "./layouts/Lists";
import { Main } from "./layouts/Main";
import { NotFound } from "./layouts/NotFound";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CurrentList } from "./layouts/CurrentList";
import { Header } from "./components/Header/Header";
import { AddToList } from "./layouts/AddToList";
import { Footer } from "./components/Footer/Footer";
import { FbLoader } from "./components/FbLikeLoader/FbLoader";
import { useActions } from "./redux/useActions";
import { useEffect } from "react";

let theme = createTheme({
  palette: {
    primary: {
      main: "#17366a",
    },
  },
});

function App() {
  const { fetchSources } = useActions();
  useEffect(() => {
    fetchSources();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Header />
        <Container maxWidth="md">
          <Routes>
            <Route path={"/"} element={<Main />}>
              <Route path={"/answer"} element={<Answer />} />
              <Route path={"/reports"} element={<Lists navigateToList />} />
              <Route path={"/report/:id"} element={<CurrentList />} />
            </Route>
            <Route path="/add-to-report" element={<AddToList />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
