export const PALETTE = {
  main: "#17366A",
};

export interface IComputationCost {
  id: number;
  title: string;
  k_retriever: number;
  k_reader: number;
  engine: string[];
}
export const costValues: IComputationCost[] = [
  {
    id: 1,
    title: "Fast search",
    k_retriever: 10,
    k_reader: 2,
    engine: ["qa_instruct"],
  },
  {
    id: 2,
    title: "Balanced search",
    k_retriever: 30,
    k_reader: 10,
    engine: ["qa_instruct", "complete_instruct"],
  },
  {
    id: 3,
    title: "Deep search",
    k_retriever: 75,
    k_reader: 20,
    engine: ["qa_instruct", "complete_instruct"],
  },
  {
    id: 4,
    title: "Maximum search",
    k_retriever: 150,
    k_reader: 50,
    engine: ["qa", "complete", "complete_instruct", "qa_instruct"],
  },
];
