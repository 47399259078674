import { MenuItem } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { Source } from "../../API";
import { useActions } from "../../redux/useActions";
import { useTypedSelector } from "../../redux/useTypedSelector";
import classes from "../Buttons/FilterButton.module.scss";

// const sources: Source[] = [
//   { domain: "http://www.guardian.com", name: "Guardian" },
//   { domain: "http://www.google.com", name: "Google" },
//   { domain: "http://www.netflix.com", name: "Netflix" },

// ];

export interface ISelectSourcesProps {
  activeStyling: string;
}
export const SelectSources: React.FC<ISelectSourcesProps> = ({
  activeStyling,
}) => {
  const sourcesState = useTypedSelector((state) => state.common.sources);
  const sources = useTypedSelector((state) => state.gql.sources);
  const { addSource } = useActions();
  const menuItemClick = (itm: Source) => {
    addSource({ type: "src", item: itm });
  };

  const filterSourceByName = (src: Source) => {
    let nameArray = sourcesState.map((src) => src.item.name);
    return nameArray.indexOf(src.name) === -1;
  };
  const filteredSources = sources.filter(filterSourceByName);
  const menuItems = filteredSources.map((itm, idx) => (
    <MenuItem
      key={idx}
      value={itm.name!}
      classes={{ root: classes.menu_item }}
      onClick={() => {
        menuItemClick(itm);
      }}
    >
      {itm.name}
    </MenuItem>
  ));

  return (
    <div
      className={clsx(
        classes.select_base,
        classes.select_panel,
        classes[activeStyling]
      )}
    >
      {menuItems}
    </div>
  );
};
