import React, { useState } from "react";
import { UnderlineButton } from "../Buttons/UnderlineButton";
import classes from "./ListItem.module.scss";
import { ReactComponent as ListIcon } from "../../assets/list_icon.svg";
import { ReactComponent as ItemDeco } from "../../assets/item_deco.svg";
import { EditListItem } from "./EditListItem";
import { DeleteListModal } from "../Modal/DeleteListModal";
import { CustomModal } from "../Modal/CustomModal";
import { useMediaQuery } from "react-responsive";
import { List, UpdateListInput } from "../../API";
import { useActions } from "../../redux/useActions";
import clsx from "clsx";

export interface IListItemProps {
  listItem: List;
  callback: (e: React.MouseEvent, listItem: any) => void;
  styling?: string | false;
}
export const ListItem: React.FC<IListItemProps> = ({
  listItem,
  callback,
  styling,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [readMore, setReadMore] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleReadMore = () => {
    setReadMore((prev) => !prev);
  };
  const { deleteListAction, updateListAction } = useActions();

  const isSmall = useMediaQuery({ query: "(min-width: 621px)" });
  const formatDescription = (text: string) => {
    if (!text) {
      return "Empty description";
    }
    let readable = text.slice(0, 150);
    let dots = text.length < 150 ? null : "...     ";
    return (
      <>
        {readable}
        {dots}
        {dots && (
          <UnderlineButton
            type="expand"
            callback={handleReadMore}
            expanded={readMore}
          />
        )}
      </>
    );
  };
  const handleListItemClick = (e: React.MouseEvent) => {
    callback(e, listItem);
  };

  const handleDeleteSelf = () => {
    setModalOpen(true);
  };
  const handleConfirmDeleteSelf = () => {
    const input = { id: listItem!.id, _version: listItem!._version };
    deleteListAction(input);
    setModalOpen(false);
  };
  const handleCancelDeleteSelf = () => {
    setModalOpen(false);
  };

  const handleEditSelf = () => {
    setIsEditing(true);
  };
  const handleConfirmEditSelf = (name: string, description: string) => {
    let input: UpdateListInput = {
      id: listItem!.id,
      _version: listItem!._version,
      name,
      description,
    };
    updateListAction(input);
    setIsEditing(false);
  };
  const handleCancelEditSelf = () => {
    setIsEditing(false);
  };

  const menuCallbacks = {
    onEdit: handleEditSelf,
    onDelete: handleDeleteSelf,
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  // console.log(styling);
  const nonEditable = (
    <div
      className={clsx(classes.item, styling)}
      data-type="listItem"
      onClick={handleListItemClick}
    >
      {isSmall && <ListIcon className={classes.avatar} />}
      <div className={classes.content} data-type="listItem">
        <h3 className={classes.title} data-type="listItem">
          {listItem.name ? listItem.name : "Noname"}
        </h3>

        <p data-type="listItem" className={classes.description}>
          {!readMore
            ? formatDescription(listItem!.description!)
            : listItem.description}
        </p>
        {readMore && (
          <UnderlineButton
            type="expand"
            callback={handleReadMore}
            expanded={readMore}
          />
        )}
      </div>

      <UnderlineButton
        callback={() => {}}
        menuCallbacks={menuCallbacks}
        type="menu"
      />
      <ItemDeco className={classes.item_deco} />
    </div>
  );

  return (
    <>
      {isEditing ? (
        <EditListItem
          handleConfirmEditing={handleConfirmEditSelf}
          handleCancelEditing={handleCancelEditSelf}
          listItem={listItem}
        />
      ) : (
        nonEditable
      )}
      <CustomModal handleClose={handleCloseModal} isActive={modalOpen}>
        <DeleteListModal
          list={listItem.name!}
          cancelDeletingCallback={handleCancelDeleteSelf}
          deleteListCallback={handleConfirmDeleteSelf}
        />
      </CustomModal>
    </>
  );
};
