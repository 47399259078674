import { LinearProgress, Tooltip } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { useTypedSelector } from "../../redux/useTypedSelector";
import { IButtonProps } from "./commonButtonData";
import classes from "./IconButton.module.scss";

export const SubscribeButton: React.FC<IButtonProps> = ({
  callback,
  caption,
  icon,
  styling,
}) => {
  const isLoading = useTypedSelector((state) => state.common.localLoading);
  const clickHandler = isLoading ? () => {} : callback;
  return (
    <Tooltip title={caption ? caption : "Button"}>
      <div
        className={clsx(classes.wrapper, styling)}
        onClick={clickHandler}
        data-type="filter"
      >
        {caption}
        {isLoading && (
          <span className={classes.button_loader}>
            <LinearProgress />
          </span>
        )}
      </div>
    </Tooltip>
  );
};
