import React from "react";
import classes from "./Modal.module.scss";
import { ReactComponent as SuccessIcon } from "../../assets/success.svg";
import { useTypedSelector } from "../../redux/useTypedSelector";

interface IAddToListModalProps {
  list: string;
}
export const AddToListModal: React.FC<IAddToListModalProps> = ({ list }) => {
  const question = useTypedSelector(
    (state) => state.common.currentItem.question
  );
  console.log(question);

  const trimedQuestion =
    question!.length < 100 ? question : question!.slice(0, 100);
  const dots = question!.length < 100 ? "" : "... ";
  const text = `Question [${trimedQuestion}${dots}] was added to [${list}] list!`;
  return (
    <>
      <h3 className={classes.title}>{text}</h3>
      <SuccessIcon />
    </>
  );
};
