import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { commonReducer } from "./commonReducer";
import { gqlReducer } from "./gqlReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  gql: gqlReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
