import { useEffect, useState } from "react";
import { useActions } from "../../redux/useActions";
import { useTypedSelector } from "../../redux/useTypedSelector";
import { IHashKeyword } from "../../types/commonTypes";
import { FilterButton } from "../Buttons/FilterButton";
import { FIlterChip } from "./FilterChip";
import classes from "./Filters.module.scss";
import { SelectCost } from "./SelectCost";
import { TagInput } from "./TagInput";

export interface IFiltersProps {}

export const Filters: React.FC<IFiltersProps> = () => {
  const [filterText, setFilterText] = useState("");
  const [sourceActive, setSourceActive] = useState(false);
  const [costActive, setCostActive] = useState(false);

  const { hashtags, keywords, sources, activeFilter, computationCost } =
    useTypedSelector((state) => state.common);
  const {
    addHashtag,
    addKeyword,
    addSource,
    removeHashtag,
    removeKeyword,
    removeSource,
    setActiveFilter,
  } = useActions();

  const handleAddHashtag = () => {
    const existTextFilter = [...hashtags, ...keywords].filter(
      (itm) => itm.item === filterText
    );
    const normalizedFilter = filterText
      .split(" ")
      .join("_")
      .replace("@", "")
      .replace("+", "")
      .replace("#", "");
    if (!existTextFilter.length) {
      switch (activeFilter) {
        case "ht":
          addHashtag({ type: "ht", item: { name: normalizedFilter } });
          break;
        case "kw":
          addKeyword({ type: "kw", item: { name: normalizedFilter } });
          break;
        case "src":
          addSource({ type: "src", item: { name: normalizedFilter } });
          break;
        default:
          break;
      }

      setFilterText("");
    }
  };

  const handleEnableHashtag = () => {
    setActiveFilter("ht");
  };
  const handleEnableKeyword = () => {
    setActiveFilter("kw");
  };
  const handleAddSource = () => {
    setSourceActive((prev) => !prev);
    setCostActive(false);
  };
  const handleSelectCost = () => {
    setCostActive((prev) => !prev);
    setSourceActive(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value);
  };
  const handleDeleteFilterFromList = (filter: IHashKeyword) => {
    switch (filter.type) {
      case "ht":
        removeHashtag(filter);
        break;
      case "kw":
        removeKeyword(filter);
        break;
      case "src":
        removeSource(filter);
        break;
    }
  };
  useEffect(() => {
    if ([...hashtags, ...keywords].length < 1) {
      setActiveFilter(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashtags, keywords, sources]);

  const displaySelectedFilters = [...keywords, ...hashtags, ...sources].map(
    (filter, idx) => {
      return (
        <FIlterChip
          key={idx}
          filter={filter}
          onClick={handleDeleteFilterFromList}
        />
      );
    }
  );
  return (
    <>
      <div className={classes.actions}>
        <FilterButton
          callback={handleEnableKeyword}
          caption="Add keyword"
          icon="key"
        />
        <FilterButton
          callback={handleEnableHashtag}
          caption="Add hashtag"
          icon="tag"
        />
        <FilterButton
          callback={handleAddSource}
          caption="Add sources"
          icon="folder"
          styling={sourceActive ? "active_source" : ""}
        />
        <FilterButton
          callback={handleSelectCost}
          caption={computationCost.title}
          icon="cost"
          styling={costActive ? "active_cost" : ""}
        />
      </div>
      <div className={classes.selectedFilters}>
        {activeFilter && (
          <TagInput
            value={filterText}
            placeholder="What is tag?)"
            onChange={handleInputChange}
            onClick={handleAddHashtag}
          />
        )}
        {displaySelectedFilters}
      </div>
    </>
  );
};
