import React from "react";
import { IButtonProps } from "./commonButtonData";
import { ReactComponent as EditBtn } from "../../assets/edit_blue_24dp.svg";
import { ReactComponent as DeleteBtn } from "../../assets/delete_blue_24dp.svg";
import { ReactComponent as Email } from "../../assets/email.svg";
import { ReactComponent as Lists } from "../../assets/lists.svg";
import { ReactComponent as Plus } from "../../assets/new_list.svg";
import { ReactComponent as SaveListBtn } from "../../assets/save_list.svg";
import { ReactComponent as DeleteListBtn } from "../../assets/cancel.svg";
import classes from "./IconButton.module.scss";
import { Tooltip } from "@mui/material";

export const IconButton: React.FC<IButtonProps> = ({
  callback,
  caption,
  icon,
  styling,
}) => {
  const selectIcon = () => {
    switch (icon) {
      case "editList":
        return <EditBtn />;
      case "deleteList":
        return <DeleteBtn />;
      case "email":
        return <Email />;
      case "lists":
        return <Lists />;
      case "plus":
        return <Plus />;
      case "confirm":
        return <SaveListBtn />;
      case "cancel":
        return <DeleteListBtn />;
      default:
        return;
    }
  };
  return (
    <Tooltip title={caption ? caption : "Button"}>
      <div className={classes.wrapper} onClick={callback} data-type="filter">
        <span className={classes.icon}>{selectIcon()}</span>
      </div>
    </Tooltip>
  );
};
