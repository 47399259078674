import clsx from "clsx";
import React from "react";
import classes from "./ListItem.module.scss";
import btnClasses from "../Buttons/IconButton.module.scss";
import { List, UpdateListInput } from "../../API";
import { ReactComponent as ListIcon } from "../../assets/list_icon.svg";
import { ReactComponent as ItemDeco } from "../../assets/item_deco.svg";
import { IconButton } from "../Buttons/IconButton";
import { SubscribeButton } from "../Buttons/SubscribeButton";
import { useTypedSelector } from "../../redux/useTypedSelector";
import { useActions } from "../../redux/useActions";

export interface IListItemCurrentProps {
  handleStartEditing: () => void;
  handleDeleteSelf: () => void;
  subscriptionHandler: () => void;
  listItem?: List;
}

export const ListItemCurrent: React.FC<IListItemCurrentProps> = ({
  listItem,
  handleStartEditing,
  handleDeleteSelf,
  subscriptionHandler,
}) => {
  const { updateSubscriptionStatusAction } = useActions();
  const email = useTypedSelector((state) => state.common.email);

  const isSubscribed =
    listItem!.subscribers &&
    listItem!.subscribers.filter((itm) => itm === email).length > 0;
  const subscribeBtnCaption = isSubscribed ? "Unsubscribe" : "Subscribe";

  const handleSubscribe = () => {
    const newSubscribers = listItem!.subscribers
      ? [...listItem!.subscribers!, email]
      : [email];
    const input: UpdateListInput = {
      id: listItem!.id,
      _version: listItem!._version,
      subscribers: newSubscribers,
    };
    updateSubscriptionStatusAction(input);
  };
  const handleUnsubscribe = () => {
    const filteredSubscribers = listItem!.subscribers!.filter(
      (itm) => itm !== email
    );
    // console.log("filteredSubscribers", filteredSubscribers);

    const input: UpdateListInput = {
      id: listItem!.id,
      _version: listItem!._version,
      subscribers: filteredSubscribers,
    };
    updateSubscriptionStatusAction(input);
  };
  const subscribeCallback = isSubscribed ? handleUnsubscribe : handleSubscribe;

  return (
    <div className={clsx(classes.item, classes.item_current)}>
      <ItemDeco className={classes.item_deco} />
      <ListIcon className={classes.avatar} />
      <div className={classes.description}>
        <h3 className={classes.title}>
          {listItem!.name ? listItem!.name : "Noname"}
        </h3>
        <p>{listItem!.description}</p>
      </div>
      <div className={classes.button_block}>
        <IconButton
          caption="Edit list"
          callback={handleStartEditing}
          icon="editList"
        />
        <IconButton
          caption="Delete list"
          callback={handleDeleteSelf}
          icon="deleteList"
        />
        <SubscribeButton
          callback={subscribeCallback}
          caption={subscribeBtnCaption}
          styling={clsx(!email && btnClasses.disable)}
        />
      </div>
    </div>
  );
};
