import classes from "./FilterChip.module.scss";
import { IHashKeyword } from "../../types/commonTypes";
import { ReactComponent as DeleteChip } from "../../assets/delete_list.svg";
import clsx from "clsx";

export interface IFilterChipProps {
  filter: IHashKeyword;
  onClick: (filter: IHashKeyword) => void;
}
export const FIlterChip: React.FC<IFilterChipProps> = (props) => {
  const handleDeleteSelf = () => {
    props.onClick(props.filter);
  };
  const text = () => {
    switch (props.filter.type) {
      case "ht":
        return `#${props.filter.item.name}`;
      case "kw":
        return `+${props.filter.item.name}`;
      case "src":
        return `@${props.filter.item.name}`;
    }
  };

  return (
    <div
      className={clsx(
        classes.wrapper,
        props.filter.type && classes[props.filter.type]
      )}
      onClick={handleDeleteSelf}
    >
      <span className={classes.text}>{text()}</span>
      <DeleteChip className={classes.icon} />
    </div>
  );
};
