import React from "react";
import classes from "./Buttons.module.scss";
import { Menu, MenuItem } from "@mui/material";

import { ReactComponent as MoreBtn } from "../../assets/more.svg";

import clsx from "clsx";

export interface IButtonProps {
  callback: () => void;
  type?: "expand" |"menu" ;
  expanded?: boolean;
  title?: string;
  icon?: string;
  styling?: string;
  menuCallbacks?: {
    onEdit: () => void;
    onDelete: () => void;
  };
}

export const UnderlineButton: React.FC<IButtonProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  const button = () => {
    switch (props.type) {
      case "expand":
        return (
          <span
            className={classes.base}
            onClick={props.callback}
            data-type="expand"
          >
            {props.expanded ? "Read less" : "Read more"}
          </span>
        );
      case "menu":
        return (
          <>
            <span
              className={classes.moreBtn}
              onClick={handleClickMenu}
              data-type="menu"
            >
              <MoreBtn />
            </span>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={props.menuCallbacks!.onEdit}>
                Edit list
              </MenuItem>
              <MenuItem onClick={props.menuCallbacks!.onDelete}>
                Delete list
              </MenuItem>
            </Menu>
          </>
        );
      default:
        return (
          <span
            className={clsx(classes.base, props.styling)}
            onClick={props.callback}
          >
            {props.title}
          </span>
        );
    }
  };

  return <>{button()}</>;
};
