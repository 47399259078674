import { Dispatch } from "react";
import {
  Filter,
  CreateAnswerInput,
  ModelListFilterInput,
  UpdateListInput,
  DeleteListInput,
  ModelListConditionInput,
  CreateListInput,
  DeleteAnswerInput,
  ModelAnswerConditionInput,
  Source,
} from "../../API";
import { MutationService } from "../../api/services/mutationService";
import {
  CommonAction,
  CommonActionTypes,
  IQuestionAnswer,
} from "../../types/commonTypes";
import {
  ICreateAnswerResponse,
  ICreateListResponse,
  IDeleteAnswerResponse,
  IDeleteListResponse,
  IUpdateListResponse,
} from "../../types/graphql/mutationService";
import { GqlAction, GqlActionTypes } from "../../types/gqlTypes";
import { QueryService } from "../../api/services/queryService";
import {
  IGetAnswerResponse,
  IGetListResponse,
} from "../../types/graphql/queryService";
import AxiosApi from "../../api/axiosInstance";
import { keyframes } from "@emotion/react";
import { ISourceResponse } from "../../types/responseTypes";

const SOURCES_API_URL =
  "https://hd7zu14hce.execute-api.us-east-1.amazonaws.com/qa/sources";

export const createAnswerAction = (qa: IQuestionAnswer, filter: Filter) => {
  return async (dispatch: Dispatch<GqlAction | CommonAction>) => {
    try {
      dispatch({ type: CommonActionTypes.API_REQUEST });
      // create answer gql
      let input: CreateAnswerInput = {
        question: qa.question,
        answer: qa.answer,
        sourceUrl: qa.sourceUrl,
        filter,
      };
      const createAnswer: ICreateAnswerResponse =
        await MutationService.getInstance().createAnswer(input);
      dispatch({ type: CommonActionTypes.API_REQUEST_SUCCESS });
      dispatch({
        type: GqlActionTypes.CREATE_ANSWER_SUCCESS,
        payload: createAnswer.createAnswer,
      });
    } catch (error: any) {
      // console.log("ERROR in createAnswerAction: ", error);

      dispatch({
        type: CommonActionTypes.API_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const addToList = (id: string, answers: string[], _version: number) => {
  return async (dispatch: Dispatch<GqlAction | CommonAction>) => {
    try {
      dispatch({ type: CommonActionTypes.API_REQUEST });
      // create answer gql
      let input: UpdateListInput = {
        id,
        answers,
        _version,
      };
      // console.log("input object in addToList", input);

      const addToList: IUpdateListResponse =
        await MutationService.getInstance().updateList(input);
      // api call add question to list
      dispatch({ type: CommonActionTypes.API_REQUEST_SUCCESS });
      dispatch({
        type: GqlActionTypes.UPDATE_LIST_SUCCESS,
        payload: addToList.updateList,
      });
    } catch (error: any) {
      // console.log("ERROR in addToList: ", error);

      dispatch({
        type: CommonActionTypes.API_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getListById = (id: string) => {
  return async (dispatch: Dispatch<GqlAction | CommonAction>) => {
    try {
      dispatch({ type: CommonActionTypes.API_REQUEST });
      // create answer gql
      const oneListResponse: IGetListResponse =
        await QueryService.getInstance().getOneList(id);
      dispatch({ type: CommonActionTypes.API_REQUEST_SUCCESS });
      dispatch({
        type: GqlActionTypes.GET_LIST_BY_ID_SUCCESS,
        payload: oneListResponse.getList,
      });
    } catch (error: any) {
      // console.log("ERROR in getListById: ", error);

      dispatch({
        type: CommonActionTypes.API_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getAllListsAction = (
  filter?: ModelListFilterInput,
  limit?: number,
  nextToken?: string
) => {
  return async (dispatch: Dispatch<GqlAction | CommonAction>) => {
    try {
      dispatch({ type: CommonActionTypes.API_REQUEST });
      const { listLists } = await QueryService.getInstance().getAllLists();
      dispatch({ type: CommonActionTypes.API_REQUEST_SUCCESS });
      dispatch({
        type: GqlActionTypes.GET_ALL_LISTS_SUCCESS,
        payload: listLists.items,
      });
    } catch (error: any) {
      // console.log("ERROR in getAllListsAction: ", error);

      dispatch({
        type: CommonActionTypes.API_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getAnswerByIdAction = (id: string) => {
  return async (dispatch: Dispatch<GqlAction | CommonAction>) => {
    try {
      dispatch({ type: CommonActionTypes.API_REQUEST });
      const getAnswer: IGetAnswerResponse =
        await QueryService.getInstance().getAnswer(id);

      dispatch({ type: CommonActionTypes.API_REQUEST_SUCCESS });
      dispatch({
        type: GqlActionTypes.GET_ANSWER_BY_ID_SUCCESS,
        payload: getAnswer.getAnswer,
      });
    } catch (error: any) {
      // console.log("ERROR in getAnswerByIdAction: ", error);

      dispatch({
        type: CommonActionTypes.API_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };
};
export const getAnswerForCurrentListAction = (idList: string[]) => {
  return async (dispatch: Dispatch<GqlAction | CommonAction>) => {
    try {
      dispatch({ type: CommonActionTypes.API_REQUEST });
      for (let item of idList) {
        const getAnswer: IGetAnswerResponse =
          await QueryService.getInstance().getAnswer(item);
        dispatch({
          type: GqlActionTypes.GET_ANSWER_FOR_CURRENT_LIST_SUCCESS,
          payload: getAnswer.getAnswer,
        });
      }

      dispatch({ type: CommonActionTypes.API_REQUEST_SUCCESS });
    } catch (error: any) {
      // console.log("ERROR in getAnswerForCurrentListAction: ", error);

      dispatch({
        type: CommonActionTypes.API_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };
};
export const clearCurrentListState = () => {
  return (dispatch: Dispatch<GqlAction>) => {
    dispatch({
      type: GqlActionTypes.CLEAR_CURRENT_LIST_STATE,
    });
  };
};

export const createListAction = (
  input: CreateListInput,
  condition?: ModelListConditionInput
) => {
  return async (dispatch: Dispatch<GqlAction | CommonAction>) => {
    try {
      dispatch({ type: CommonActionTypes.API_REQUEST });
      // create answer gql
      const { createList }: ICreateListResponse =
        await MutationService.getInstance().createList(input, condition);

      dispatch({ type: CommonActionTypes.API_REQUEST_SUCCESS });
      dispatch({
        type: GqlActionTypes.CREATE_LIST_SUCCESS,
        payload: createList,
      });
    } catch (error: any) {
      // console.log("ERROR in createListAction: ", error);

      dispatch({
        type: CommonActionTypes.API_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const deleteListAction = (
  input: DeleteListInput,
  condition?: ModelListConditionInput
) => {
  return async (dispatch: Dispatch<GqlAction | CommonAction>) => {
    try {
      dispatch({ type: CommonActionTypes.API_REQUEST });
      // create answer gql
      const { deleteList }: IDeleteListResponse =
        await MutationService.getInstance().deleteList(input, condition);
      dispatch({ type: CommonActionTypes.API_REQUEST_SUCCESS });
      dispatch({
        type: GqlActionTypes.DELETE_LIST_SUCCESS,
        payload: deleteList,
      });
    } catch (error: any) {
      // console.log("ERROR in deleteListAction: ", error);

      dispatch({
        type: CommonActionTypes.API_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateListAction = (
  input: UpdateListInput,
  condition?: ModelListConditionInput
) => {
  return async (dispatch: Dispatch<GqlAction | CommonAction>) => {
    try {
      dispatch({ type: CommonActionTypes.API_REQUEST });
      const { updateList }: IUpdateListResponse =
        await MutationService.getInstance().updateList(input, condition);

      dispatch({ type: CommonActionTypes.API_REQUEST_SUCCESS });
      dispatch({
        type: GqlActionTypes.UPDATE_LIST_SUCCESS,
        payload: updateList,
      });
    } catch (error: any) {
      // console.log("ERROR in deleteListAction: ", error);

      dispatch({
        type: CommonActionTypes.API_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateQuestionMailingStatusAction = (
  input: UpdateListInput,
  condition?: ModelListConditionInput
) => {
  return async (dispatch: Dispatch<GqlAction | CommonAction>) => {
    try {
      const { updateList }: IUpdateListResponse =
        await MutationService.getInstance().updateList(input, condition);
      let deactivated = updateList.deactivated ? updateList.deactivated : [];
      dispatch({
        type: GqlActionTypes.UPDATE_QUESTION_MAILING,
        payload: { deactivated, _version: updateList._version },
      });
    } catch (error: any) {
      // console.log("ERROR in deleteListAction: ", error);

      dispatch({
        type: CommonActionTypes.API_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateSubscriptionStatusAction = (
  input: UpdateListInput,
  condition?: ModelListConditionInput
) => {
  return async (dispatch: Dispatch<GqlAction | CommonAction>) => {
    try {
      dispatch({
        type: CommonActionTypes.SET_LOCAL_LOADING,
        payload: true,
      });
      // console.log("Log from updateSubscriptionStatusAction: ", input);
      const { updateList }: IUpdateListResponse =
        await MutationService.getInstance().updateList(input, condition);
      let subscribers = updateList.subscribers ? updateList.subscribers : [];
      // console.log("Log from updateSubscriptionStatusAction: ", updateList);
      // console.log("Log from updateSubscriptionStatusAction: ", subscribers);

      dispatch({
        type: GqlActionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
        payload: { subscribers, _version: updateList._version },
      });
      dispatch({
        type: CommonActionTypes.SET_LOCAL_LOADING,
        payload: false,
      });
    } catch (error: any) {
      // console.log("ERROR in deleteListAction: ", error);

      dispatch({
        type: CommonActionTypes.API_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const deleteAnswerAction = (
  answerInput: DeleteAnswerInput,
  currentListInput: UpdateListInput,
  condition?: ModelAnswerConditionInput
) => {
  return async (dispatch: Dispatch<GqlAction | CommonAction>) => {
    try {
      console.log("Start action");

      const { deleteAnswer }: IDeleteAnswerResponse =
        await MutationService.getInstance().deleteAnswer(
          answerInput,
          condition
        );

      const { updateList }: IUpdateListResponse =
        await MutationService.getInstance().updateList(
          currentListInput,
          condition
        );
      dispatch({
        type: GqlActionTypes.UPDATE_LIST_SUCCESS,
        payload: updateList,
      });
      console.log("Finish action");
    } catch (error: any) {
      console.log("ERROR in deleteAnswerAction: ", error);

      dispatch({
        type: CommonActionTypes.API_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const fetchSources = () => {
  return async (dispatch: Dispatch<CommonAction|GqlAction>) => {
    try {
      dispatch({ type: CommonActionTypes.API_REQUEST });

      const { data } = await AxiosApi.sourceInstance.post<
      ISourceResponse[]
      >(SOURCES_API_URL);

      const adaptedSources = data.map((src) => {
        let result: Source = {
          domain: src.domain,
          name: src.name,
          logoUrl: src.logoUrl
        };
        return result;
      });
      console.log(data);

      dispatch({
        type: GqlActionTypes.FETCH_SOURCES_SUCCESS,
        payload: adaptedSources,
      });

      dispatch({ type: CommonActionTypes.API_REQUEST_SUCCESS });
    } catch (error: any) {
      dispatch({ type: CommonActionTypes.ANSWER_FAILURE });
      dispatch({
        type: CommonActionTypes.API_REQUEST_FAILURE,
        payload: error.message,
      });
    }
  };
};
