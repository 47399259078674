import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import classes from "./Modal.module.scss";
export interface IModalProps {
  isActive: boolean;
  handleClose: () => void;
}

export const CustomModal: React.FC<IModalProps> = (props) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.isActive}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
      }}
    >
      <Fade in={props.isActive}>
        <div className={classes.panel}>{props.children}</div>
      </Fade>
    </Modal>
  );
};
