import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { CommonActionTypes } from "../../types/commonTypes";
import { CustomModal } from "../Modal/CustomModal";
import { EmailModal } from "../Modal/EmailModal";
import classes from "./Header.module.scss";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { IconButton } from "../Buttons/IconButton";

export const Header: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleEmailClick = () => {
    setModalOpen(true);
  };
  const handleListsClick = () => {
    navigate("/reports");
  };
  const navigateRoot = () => {
    dispatch({ type: CommonActionTypes.CLEAR_FILTERS });
    navigate("/");
  };
  return (
    <div className={classes.container}>
      <span className={classes.title} onClick={navigateRoot}>
        <Logo />
      </span>
      <nav className={classes.links}>
        <IconButton
          callback={handleEmailClick}
          icon={"email"}
          caption="E-mail"
        />
        <IconButton
          callback={handleListsClick}
          icon={"lists"}
          caption="Reports"
        />
      </nav>
      <CustomModal handleClose={handleCloseModal} isActive={modalOpen}>
        <EmailModal closeModal={handleCloseModal} />
      </CustomModal>
    </div>
  );
};
