import {
  CommonAction,
  CommonActionTypes,
  ICommonState,
} from "../../types/commonTypes";

let initialState: ICommonState = {
  isLoading: false,
  localLoading: false,
  error: "",
  email: "",
  hashtags: [],
  keywords: [],
  sources: [],
  currentItem: { question: "", answer: "" },
  activeFilter: null,
  computationCost: {
    id: 1,
    title: "Fast search",
    k_retriever: 10,
    k_reader: 2,
    engine: ["qa_instruct"],
  },
};

export const commonReducer = (
  state = initialState,
  action: CommonAction
): ICommonState => {
  switch (action.type) {
    case CommonActionTypes.ADD_KEYWORD:
      return {
        ...state,
        keywords: [...state.keywords, action.payload].filter(
          (v, i, a) => a.findIndex((t) => t.item.name === v.item.name) === i
        ),
      };
    case CommonActionTypes.REMOVE_KEYWORD:
      const filteredKeywords = state.keywords.filter(
        (kw) => kw.item.name !== action.payload.item.name
      );
      return {
        ...state,
        keywords: [...filteredKeywords],
      };
    case CommonActionTypes.ADD_HASHTAG:
      return {
        ...state,
        hashtags: [...state.hashtags, action.payload].filter(
          (v, i, a) => a.findIndex((t) => t.item.name === v.item.name) === i
        ),
      };
    case CommonActionTypes.REMOVE_HASHTAG:
      const filteredHashtags = state.hashtags.filter(
        (ht) => ht.item.name !== action.payload.item.name
      );
      return {
        ...state,
        hashtags: [...filteredHashtags],
      };
    case CommonActionTypes.ADD_SOURCE:
      return {
        ...state,
        sources: [...state.sources, action.payload].filter(
          (v, i, a) => a.findIndex((t) => t.item.name === v.item.name) === i
        ),
      };
    case CommonActionTypes.REMOVE_SOURCE:
      const filteredSources = state.sources.filter(
        (src) => src.item.name !== action.payload.item.name
      );
      return {
        ...state,
        sources: [...filteredSources],
      };
    case CommonActionTypes.ADD_BATCH_FILTERS:
      let newHt = [...state.hashtags, ...action.hashtags].filter(
        (v, i, a) => a.findIndex((t) => t.item.name === v.item.name) === i
      );
      let newKw = [...state.keywords, ...action.keywords].filter(
        (v, i, a) => a.findIndex((t) => t.item.name === v.item.name) === i
      );
      let newSrc = [...state.sources, ...action.sources].filter(
        (v, i, a) => a.findIndex((t) => t.item.name === v.item.name) === i
      );
      return {
        ...state,
        hashtags: [...newHt],
        keywords: [...newKw],
        sources: [...newSrc],
      };
    case CommonActionTypes.CLEAR_FILTERS:
      return {
        ...state,
        hashtags: [],
        keywords: [],
        sources: [],
        currentItem: { question: "", answer: "" },
      };
    case CommonActionTypes.ASK_QUESTION:
      return {
        ...state,
        currentItem: { ...state.currentItem, question: action.payload },
      };
    case CommonActionTypes.ANSWER_SOURCE:
      return {
        ...state,
        currentItem: { ...state.currentItem, sourceUrl: action.payload},
      };
    case CommonActionTypes.ANSWER_SUCCESS:
      return {
        ...state,
        currentItem: { ...state.currentItem, answer: action.payload},
      };

    case CommonActionTypes.SET_ACTIVE_FILTER:
      return {
        ...state,
        activeFilter: action.payload,
      };
    case CommonActionTypes.API_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CommonActionTypes.API_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case CommonActionTypes.API_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CommonActionTypes.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case CommonActionTypes.SET_LOCAL_LOADING:
      return {
        ...state,
        localLoading: action.payload,
      };
    case CommonActionTypes.SET_COMPUTATION_COST:
      return {
        ...state,
        computationCost: action.payload,
      };

    default:
      return state;
  }
};
