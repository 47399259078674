import clsx from "clsx";
import React from "react";
import classes from "./Buttons.module.scss";
import { IButtonProps } from "./commonButtonData";


export const SimpleButton: React.FC<IButtonProps> = ({
  caption,
  callback,
  styling,
}) => {
  const style = clsx(classes.baseButton, styling);
  return (
    <button onClick={callback} className={style}>
      {caption}
    </button>
  );
};
