// state
export interface IAuthState {
  currentUser: ICurrentUser | null;
  loggedIn: boolean;
}
// action types
export enum AuthActionTypes {
  AUTH_API_REQUEST = "AUTH_API_REQUEST",
  AUTH_API_REQUEST_FAILURE = "AUTH_API_REQUEST_FAILURE",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  LOGOUT = "LOGOUT",
  CHECK_TOKEN = "CHECK_TOKEN",
  ADD_DEVELOPER_SUCCESS = "ADD_DEVELOPER_SUCCESS",
}

interface IAuthApiRequestAction {
  type: AuthActionTypes.AUTH_API_REQUEST;
}
interface ILoginRequestSuccessAction {
  type: AuthActionTypes.LOGIN_SUCCESS;
  payload: ILoginResponse;
}
interface ILoginRequestFailureAction {
  type: AuthActionTypes.LOGIN_FAILURE;
  payload: string;
}
interface ILogoutAction {
  type: AuthActionTypes.LOGOUT;
}
interface ICheckToken {
  type: AuthActionTypes.CHECK_TOKEN;
  payload: ICurrentUser;
}
interface IAddDeveloperSuccessAction {
  type: AuthActionTypes.ADD_DEVELOPER_SUCCESS;
}
interface IAuthApiRequestFailureAction {
  type: AuthActionTypes.AUTH_API_REQUEST_FAILURE;
  payload: string;
}

export type AuthAction =
  | IAuthApiRequestAction
  | ILoginRequestSuccessAction
  | ILoginRequestFailureAction
  | ILogoutAction
  | ICheckToken
  | IAddDeveloperSuccessAction
  | IAuthApiRequestFailureAction;

// object types
export interface ILoginResponse {
  access_token: string;
  token_type: string;
}

export interface ILogoutResponse {
  status: string;
  detail: string;
}
export interface ILoginParams {
  username: string;
  password: string;
}

export interface ICurrentUser {
  username: string;
}
