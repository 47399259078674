import React, { useEffect, useState } from "react";
import classes from "./ListItem.module.scss";
import { ReactComponent as ListIcon } from "../../assets/list_icon.svg";
import clsx from "clsx";
import { useMediaQuery } from "react-responsive";
import { List } from "../../API";
import { IconButton } from "../Buttons/IconButton";

export interface IEditListItemProps {
  handleConfirmEditing: (name: string, description: string) => void;
  handleCancelEditing: () => void;
  listItem?: List;
}

export const EditListItem: React.FC<IEditListItemProps> = ({
  handleConfirmEditing,
  handleCancelEditing,
  listItem,
}) => {
  const [newTitle, setNewTitle] = useState("");
  const [newDescription, setNewDescription] = useState("");

  const isSmall = useMediaQuery({ query: "(min-width: 621px)" });

  useEffect(() => {
    if (listItem) {
      setNewTitle(listItem.name ? listItem.name : "Noname");
      setNewDescription(
        listItem.description ? listItem.description : "Description will be here"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTitle(e.target.value);
  };

  const handleChangeDescription = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNewDescription(e.target.value);
  };

  const handleConfirmButtonClick = () => {
    if (newTitle) handleConfirmEditing(newTitle, newDescription);
  };

  return (
    <div className={clsx(classes.item, classes.item_edit)} data-type="listItem">
      {isSmall && <ListIcon className={classes.avatar} />}
      <div className={classes.content} data-type="listItem">
        <input
          type="text"
          className={clsx(classes.title_edit, classes.title)}
          placeholder="New report title"
          value={newTitle}
          onChange={handleChangeTitle}
        ></input>
        <textarea
          className={clsx(classes.description_edit)}
          placeholder="New report description"
          value={newDescription}
          onChange={handleChangeDescription}
          rows={7}
        ></textarea>
      </div>
      <div className={classes.edit_button_block}>
        <IconButton
          callback={handleConfirmButtonClick}
          caption="Confirm"
          icon="confirm"
        />
        <IconButton
          callback={handleCancelEditing}
          caption="Cancel"
          icon="cancel"
        />
      </div>
    </div>
  );
};
