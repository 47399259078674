import { useNavigate, useParams } from "react-router";
import classes from "../components/ListItem/ListItem.module.scss";
import { Questions } from "./Questions";
import { ListItemCurrent } from "../components/ListItem/ListItemCurrent";
import { EditListItem } from "../components/ListItem/EditListItem";
import { useEffect, useState } from "react";
import { CustomModal } from "../components/Modal/CustomModal";
import { DeleteListModal } from "../components/Modal/DeleteListModal";
import { useActions } from "../redux/useActions";
import { useTypedSelector } from "../redux/useTypedSelector";
import { CircularProgress } from "@mui/material";
import { UpdateListInput } from "../API";
import { FbLoader } from "../components/FbLikeLoader/FbLoader";

export const CurrentList: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [updateSubscription, setUpdateSubscription] = useState(false);
  const [questionComponent, setQuestionComponent] = useState<
    JSX.Element | string | null
  >(null);
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const listItem = useTypedSelector((state) => state.gql.currentList);
  const isLoading = useTypedSelector((state) => state.common.isLoading);
  const currentAnswersList = listItem?.answers as string[];
  const { id } = useParams();
  const {
    getListById,
    clearCurrentListState,
    deleteListAction,
    updateListAction,
  } = useActions();
  const navigate = useNavigate();

  // let listItem = dummyListItems.find((el) => el.id === +id!);
  useEffect(() => {
    getListById(id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSubscription]);

  useEffect(() => {
    return () => {
      clearCurrentListState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const questionsSection =
      currentAnswersList && !isLoading ? (
        <Questions idList={currentAnswersList} />
      ) : (
        "Report is empty!"
      );
    setQuestionComponent(questionsSection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnswersList]);

  const handleConfirmEditing = (name: string, description: string) => {
    let input: UpdateListInput = {
      id: listItem?.id!,
      _version: listItem?._version,
      name,
      description,
    };
    updateListAction(input);
    setIsEditing(false);
  };
  const handleCancelEditing = () => {
    setIsEditing(false);
  };
  const handleStartEditing = () => {
    setIsEditing(true);
  };
  const handleDeleteSelf = () => {
    setModalOpen(true);
  };
  const handleUpdateSubscription = () => {
    setUpdateSubscription((prev) => !prev);
  };

  const handleConfirmDeleteSelf = () => {
    const input = { id: listItem!.id, _version: listItem!._version };
    deleteListAction(input);
    setModalOpen(false);
    navigate("/reports");
  };
  const handleCancelDeleteSelf = () => {
    setModalOpen(false);
  };

  if (!listItem) {
    return <FbLoader />;
  }
  return (
    <>
      {!isEditing ? (
        <ListItemCurrent
          listItem={listItem!}
          handleStartEditing={handleStartEditing}
          handleDeleteSelf={handleDeleteSelf}
          subscriptionHandler={handleUpdateSubscription}
        />
      ) : (
        <EditListItem
          handleConfirmEditing={handleConfirmEditing}
          handleCancelEditing={handleCancelEditing}
          listItem={listItem!}
        />
      )}
      {listItem && (
        <div className={classes.answers_panel}>{questionComponent}</div>
      )}

      <CustomModal handleClose={handleCloseModal} isActive={modalOpen}>
        <DeleteListModal
          cancelDeletingCallback={handleCancelDeleteSelf}
          deleteListCallback={handleConfirmDeleteSelf}
          list={listItem!.name!}
        />
      </CustomModal>
    </>
  );
};
