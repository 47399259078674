import React, { useEffect, useState, FocusEvent } from "react";
import buttonClasses from "../Buttons/IconButton.module.scss";
import classes from "./SearchBar.module.scss";
import { MyInput } from "./MyInput";
import { MyButton } from "./MyButton";
import { useNavigate } from "react-router";
import { useActions } from "../../redux/useActions";
import { useTypedSelector } from "../../redux/useTypedSelector";
import { CommonActionTypes, IHashKeyword } from "../../types/commonTypes";
import { ReactComponent as DeleteIcon } from "../../assets/delete_24.svg";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { Source } from "../../API";

export const SearchBar: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false);
  // const [question, setQuestion] = useState<string>("");
  const { askQuestion, addBatchFilters } = useActions();
  const keywords = useTypedSelector((state) => state.common.keywords);
  const hashtags = useTypedSelector((state) => state.common.hashtags);
  const sources = useTypedSelector((state) => state.common.sources);
  const computationCost = useTypedSelector((state) => state.common.computationCost);
  const currentQuestion = useTypedSelector(
    (state) => state.common.currentItem.question
  );
  const isLoading = useTypedSelector((state) => state.common.isLoading);

  const question = currentQuestion ? currentQuestion : "";

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const data = e.nativeEvent.data;
    if (!question && data === " ") return;

    dispatch({ type: CommonActionTypes.ASK_QUESTION, payload: e.target.value });
  };

  // const filterSourceByName = (src: Source) => {
  //   let nameArray = fetchedSources.map((src) => src.item.name);
  //   return nameArray.indexOf(src.name) === -1;
  // };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Space") {
      // const hashtag: IHashKeyword[] = question.split(" ").slice(-1)[0].split("#")[1] ? [{ type: "ht", item: { name: question.split(" ").slice(-1)[0].split("#")[1] } }] : [];
      // const keyword: IHashKeyword[] = question.split(" ").slice(-1)[0].split("+")[1] ? [{ type: "kw", item: { name: question.split(" ").slice(-1)[0].split("+")[1] } }] : [];
      // const source: IHashKeyword[] = question.split(" ").slice(-1)[0].split("@")[1] ? [{ type: "src", item: { name: question.split(" ").slice(-1)[0].split("@")[1] } }] : [];

      // console.log(hashtag);
      // console.log(keyword);
      // console.log(source);
      // // console.log(`[${question}]`);
      // // extractFiltersFromQuestion();
      // addBatchFilters(hashtag, keyword, source);
      extractFiltersFromQuestion();
    }
  };

  const extractFiltersFromQuestion = () => {
    const hashtags: IHashKeyword[] = question
      .split("#")
      .slice(1)
      .map((text) => {
        return { type: "ht", item: { name: text.split(" ")[0] } };
      });

    const keywords: IHashKeyword[] = question
      .split("+")
      .slice(1)
      .map((text) => {
        return { type: "kw", item: { name: text.split(" ")[0] } };
      });
    const rawSources: IHashKeyword[] = question
      .split("@")
      .slice(1)
      .map((text) => {
        return { type: "src", item: { name: text.split(" ")[0] } };
      });
    const sources: IHashKeyword[] = rawSources.map((src) => {
      return src;
    });
    addBatchFilters(hashtags, keywords, sources);
  };

  const handleSubmit = (
    e: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLFormElement>
  ) => {
    if (!isLoading) {
      e.preventDefault();
      // console.log("question :>> ", question);
      extractFiltersFromQuestion();
      setShouldSubmit(true);
      navigate("/answer");
    }
  };

  useEffect(() => {
    if (shouldSubmit) {
      // console.log("here");
      askQuestion(question, hashtags, keywords, sources, computationCost);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSubmit]);

  const handleClearSearchBar = () => {
    setShouldSubmit(false);
    dispatch({ type: CommonActionTypes.CLEAR_FILTERS });
    // setQuestion("");
  };
  const onFocusEvent = (e: FocusEvent<HTMLInputElement>) => {
    if (shouldSubmit) {
      setShouldSubmit((prev) => !prev);
    }
  };
  return (
    <div className={classes.wrapper}>
      <MyInput
        value={currentQuestion ? currentQuestion : ""}
        placeholder="Ask your question here"
        onChange={handleInput}
        onKeyDown={handleKeyDown}
        onFocus={onFocusEvent}
        styling={clsx(classes.searchInput, question && classes.inputWithText)}
      />
      <div
        className={clsx(classes.deleteIcon, !question && classes.iconDisabled)}
        onClick={handleClearSearchBar}
      >
        <DeleteIcon />
      </div>
      <MyButton
        onClick={handleSubmit}
        styling={clsx(!currentQuestion && buttonClasses.disable)}
      />
    </div>
  );
};
