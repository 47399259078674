import { Outlet } from "react-router";
import { Filters } from "../components/Filters/Filters";
import { SearchBar } from "../components/SearchBar/SearchBar";

export const Main: React.FC = () => {
  return (
    <>
      <SearchBar />
      <Filters />
      <Outlet />
    </>
  );
};
