import {Button, CircularProgress} from "@mui/material";
import { useNavigate } from "react-router";
import { Filter, Source } from "../API";
import { IconButton } from "../components/Buttons/IconButton";
import { FbLoader } from "../components/FbLikeLoader/FbLoader";
import { useActions } from "../redux/useActions";
import { useTypedSelector } from "../redux/useTypedSelector";
import classes from "./Main.module.scss";

export const Answer: React.FC = () => {
  const navigate = useNavigate();
  const currentItem = useTypedSelector((state) => state.common.currentItem);

  const hashtags = useTypedSelector((state) => state.common.hashtags);
  const keywords = useTypedSelector((state) => state.common.keywords);
  const sources = useTypedSelector((state) => state.common.sources);

  const isLoading = useTypedSelector((state) => state.common.isLoading);

  const { createAnswerAction } = useActions();

  const filter: Filter = {
    hashtags: hashtags.map((ht) => ht.item.name!),
    keywords: keywords.map((kw) => kw.item.name!),
    sources: sources.map((src) => {
      const srcObj: Source = {
        name: src.item.name,
        domain: src.item.domain,
        logoUrl: src.item.logoUrl,
      };
      return srcObj;
    }),
  };

  // console.log("Answer layout: ", currentItem.answer);

  const handleAddToList = () => {
    // console.log("handleAddToList");
    createAnswerAction(currentItem, filter);
    navigate("/add-to-report");
  };
  console.log("Hyperlinks", currentItem)

  let answerSources: any;

  answerSources = currentItem.sourceUrl?.map((su) => {
    return (
      <a
        href={`${su.highlighted_link}`}
        className={classes.source_link}
        target={"_blank"}
        rel="noreferrer"
      >{`${su.name}`}</a>
    );
  })

  const hyperlinks = sources.map((hl) => {
    return (
      <a
        href={`//${hl.item.domain}`}
        className={classes.source_link}
        target={"_blank"}
        rel="noreferrer"
      >{`${hl.item.name}`}</a>
    );
  });

  if (isLoading) {
    return (
      <div className={classes.answer_wrapper}>
        <FbLoader />
      </div>
    );
  }

  let quotes = ["I visualize a time when we will be to robots what dogs are to humans. And I am rooting for the machines.",
"Information is the resolution of uncertainty.",
"Use the word 'cybernetics', because nobody knows what it means. This will always put you at an advantage in arguments.",
"We know the past but cannot control it. We control the future but cannot know it.",
"Information is the negative reciprocal value of probability."]

  if (!currentItem.answer) {
    return <div className={classes.answer_wrapper}>
      <p>
      {quotes[Math.floor((Math.round(Date.now()/(1000*60)))%quotes.length)]}
      </p>
      <p>
      <i>- Claude Shannon</i>
        </p>
    </div>;
  }

  return (
    <div className={classes.answer_wrapper}>
      <p className={classes.answer_text}>
        {currentItem.answer}
        {answerSources.length > 0 ? <hr></hr> : null}{" "}
        {answerSources ? answerSources : null}
      </p>
      <div className={classes.answer_button}>
        <IconButton
          callback={handleAddToList}
          caption="Add to report"
          icon="plus"
        />
      </div>
    </div>
  );
};
