import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { CreateListInput } from "../API";
import { IconButton } from "../components/Buttons/IconButton";
import { EditListItem } from "../components/ListItem/EditListItem";
import { ListItem } from "../components/ListItem/ListItem";
import { useActions } from "../redux/useActions";
import { useTypedSelector } from "../redux/useTypedSelector";
import classes from "./Lists.module.scss";
import itemClasses from "../components/ListItem/ListItem.module.scss";
import { FbLoader } from "../components/FbLikeLoader/FbLoader";

export interface IListsProps {
  itemClickCallback?: (...args: any[]) => void;
  navigateToList?: boolean;
  parentComponent?: string;
}
export const Lists: React.FC<IListsProps> = ({
  itemClickCallback,
  navigateToList,
  parentComponent,
}) => {
  const [showCreateNewList, setShowCreateNewList] = useState(false);
  const navigate = useNavigate();
  const { createListAction, getAllListsAction } = useActions();
  const isLoading = useTypedSelector((state) => state.common.isLoading);
  const allLists = useTypedSelector((state) => state.gql.allLists);

  const handleListItemClick = (e: React.MouseEvent, listItem: any) => {
    let tag = (e.target as HTMLElement).dataset.type;
    if (tag === "listItem" && itemClickCallback) {
      itemClickCallback(e, listItem);
    }
    if (tag === "listItem" && navigateToList) {
      navigate(`/report/${listItem.id}`);
    }
  };

  useEffect(() => {
    getAllListsAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const listItems = null;
  const listItems = allLists.map((itm, idx) => {
    if (itm._deleted) {
      return null;
    }
    return (
      <ListItem
        key={idx}
        listItem={itm}
        callback={handleListItemClick}
        styling={itemClasses.item_add_to_list}
      />
    );
  });

  const handleEnableCreating = () => {
    setShowCreateNewList(true);
  };
  const handleConfirmEditing = (name: string, description: string) => {
    let input: CreateListInput = { name, description };
    createListAction(input);
    setShowCreateNewList(false);
  };
  const handleCancelEditing = () => {
    setShowCreateNewList(false);
  };
  if (isLoading) {
    return <FbLoader />;
  }

  return (
    <>
      <div className={classes.header}>
        <span className={classes.title}>Reports</span>
        <IconButton
          icon="plus"
          callback={handleEnableCreating}
          caption="New report"
        />
      </div>
      {showCreateNewList && (
        <EditListItem
          handleConfirmEditing={handleConfirmEditing}
          handleCancelEditing={handleCancelEditing}
        />
      )}
      {listItems ? listItems : "Please, create your first report!"}
    </>
  );
};
