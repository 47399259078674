import axios, { AxiosInstance } from "axios";

export default class AxiosApi {
  static get instance(): AxiosInstance {
    return axios.create({});
  }
  static get sourceInstance(): AxiosInstance {
    // const token = localStorage.getItem("token") ?? "";
    const token = "ZWxhc3RpYzpmU1FJcnd6QmVjQ2ticjh0ckZJMkRiZm0=";
    return axios.create({
      // baseURL: "localhost",
      //   baseURL: domain.REACT_DOMAIN,
      headers: { Authorization: `Basic ${token}` },
    });
  }
}
