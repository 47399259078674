import React from "react";
import classes from "./Modal.module.scss";

export interface ISendEmailModalProps {
  status: boolean;
}
export const SendEmailModal: React.FC<ISendEmailModalProps> = ({ status }) => {
  const addText = `Answer on this question will be send to your e-mail!`;
  const removeText = `Answer on this question will be delete from e-mail list!`;

  return (
    <>
      <h3 className={classes.title}>{status ? addText : removeText}</h3>
    </>
  );
};
