import { graphqlOperation } from "aws-amplify";

import {
  IBaseResponse,
  IListListsResponse,
  IGetListResponse,
  Query,
  ISyncListsResponse,
  IGetAnswerResponse,
  IListAnswersResponse,
  ISyncAnswersResponse,
} from "../../types/graphql/queryService";
import { GqlApi } from "../amplifyClient";
import {
  getList,
  listLists,
  syncLists,
  getAnswer,
  listAnswers,
  syncAnswers,
} from "../../graphql/queries";
import { ModelListFilterInput, ModelAnswerFilterInput } from "../../API";

export class QueryService implements Query {
  private static myInstance: QueryService;
  public static getInstance() {
    if (!QueryService.myInstance) {
      QueryService.myInstance = new QueryService();
    }
    return this.myInstance;
  }
  private client;

  private constructor() {
    this.client = GqlApi.getInstance().API;
  }
  // =================== Main methods ====================
  // TODO: add try - catch for api calls
  async getOneList(id: string) {
    const response: IBaseResponse<IGetListResponse> = await this.client.graphql(
      graphqlOperation(getList, { id })
    );
    // console.log("LOG FROM getOneList");
    // console.log(response);
    return response.data;
  }
  async getAllLists(
    filter?: ModelListFilterInput,
    limit?: number,
    nextToken?: string
  ) {
    const response: IBaseResponse<IListListsResponse> =
      await this.client.graphql(
        graphqlOperation(listLists, { filter, limit, nextToken })
      );
    // console.log("LOG FROM getAllLists");
    // console.log(response);
    return response.data;
  }
  async syncLists(
    filter?: ModelListFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: string
  ) {
    const response: IBaseResponse<ISyncListsResponse> =
      await this.client.graphql(
        graphqlOperation(syncLists, { filter, limit, nextToken, lastSync })
      );
    // console.log("LOG FROM syncLists");
    // console.log(response.data.syncLists.items);
    return response.data.syncLists;
  }
  async getAnswer(id: string) {
    const response: IBaseResponse<IGetAnswerResponse> =
      await this.client.graphql(graphqlOperation(getAnswer, { id }));
    // console.log("LOG FROM getAnswer");
    // // console.log(response.data);
    return response.data;
  }
  async listAnswers(
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ) {
    const response: IBaseResponse<IListAnswersResponse> =
      await this.client.graphql(
        graphqlOperation(listLists, { listAnswers, limit, nextToken })
      );
    // console.log("LOG FROM listAnswers");
    // console.log(response);
    return response.data;
  }
  async syncAnswers(
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: string
  ) {
    const response: IBaseResponse<ISyncAnswersResponse> =
      await this.client.graphql(
        graphqlOperation(syncAnswers, { filter, limit, nextToken, lastSync })
      );
    // console.log("LOG FROM syncAnswers");
    // console.log(response.data.syncAnswers);
    return response.data;
  }
}
