import * as AuthActionCreators from "./authActions";
import * as CommonActionCreators from "./commonActions";
import * as GqlActionCreators from "./gqlActions";

const actions = {
  ...AuthActionCreators,
  ...CommonActionCreators,
  ...GqlActionCreators,
};
export default actions;
