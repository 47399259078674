import React from "react";
import { ReactComponent as Key } from "../../assets/keyword.svg";
import { ReactComponent as Hashtag } from "../../assets/hashtag.svg";
import { ReactComponent as Folder } from "../../assets/source.svg";
import { ReactComponent as Cost } from "../../assets/computations.svg";
import { ReactComponent as SourcesChevron } from "../../assets/sources_chevron.svg";
import classes from "./FilterButton.module.scss";
import clsx from "clsx";
import { IButtonProps } from "./commonButtonData";
import { SelectSources } from "../Filters/SelectSources";
import { useTypedSelector } from "../../redux/useTypedSelector";
import { CircularProgress } from "@mui/material";
import { SelectCost } from "../Filters/SelectCost";

export const FilterButton: React.FC<IButtonProps> = ({
  callback,
  icon,
  styling,
  caption: title,
}) => {
  const sources = useTypedSelector((state) => state.gql.sources);
  const selectIcon = () => {
    switch (icon) {
      case "tag":
        return <Hashtag />;
      case "key":
        return <Key />;
      case "folder":
        return <Folder />;
      case "cost":
        return <Cost />;
      default:
        return <Folder />;
    }
  };

  return (
    <div className={classes.wrapper} onClick={callback} data-type="filter">
      <span className={classes.icon}>{selectIcon()}</span>
      <span className={classes.text}>{title}</span>
      {(icon === "folder" || icon === "cost") &&
        (sources.length > 0 ? (
          <span
            className={clsx(
              classes.chevron_passive,
              styling && classes.chevron_active
            )}
          >
            <SourcesChevron />
          </span>
        ) : (
          <CircularProgress classes={{ root: classes.select_loader }} />
        ))}
      {styling === "active_source" && <SelectSources activeStyling={styling} />}
      {styling === "active_cost" && <SelectCost activeStyling={styling} />}
    </div>
  );
};
